import { CBadge, CCol, CRow } from '@coreui/react';
import cs from 'classnames';
import React from 'react';
import Button from '../../../components/Button/Button';
import DataCard from '../../../components/DataCard/DataCard';
import DataRow from '../../../components/DataRow/DataRow';
import { downloadFile } from '../../../helpers';
import classes from '../../Application/Application.module.css';
// import ProgressValue from "./ProgressValue";
import ApplicationService from '../../../services/ApplicationService';
import LoaderService from '../../../services/LoaderService';
import { toast } from 'react-toastify';
import ProgressValue from '../../Application/components/ProgressValue';
import IncomeAnalysis from './IncomeAnalysis';

const ScoringBox = function ({
  customerId,
  customerType,
  scoring_summary: {
    big_points,
    big_points_max,
    income_points,
    income_points_max,
    liabilities_points,
    liabilities_points_max,
    loyalty_points,
    loyalty_points_max,
    property_separation_points,
    property_separation_points_max,
    scoring,
    total_points,
    total_points_max,
  },
  income_analysis,
  big_report: {
    big_negative_count,
    big_positive_count,
    big_queries_count,
    bik_negative_count,
    bik_report_link,
    entrepreneur_start_date,
    krs_report_link,
    statements_link,
    enablement_link,
    pesel_ok,
    pesel_restricted,
  },
  client_status,
  refetch,
}) {
  const downloadStatement = async (event) => {
    event.preventDefault();

    try {
      LoaderService.show();
      await ApplicationService.downloadFromLink(
        statements_link,
        'statement.zip'
      );
    } catch (e) {
      toast.error('Nie znaleziono pliku');
    } finally {
      LoaderService.hide();
    }
  };

  return (
    <DataCard title="Scoring">
      <CRow>
        <CCol sm="4" xl="4">
          <div className={cs('mt-5 mb-3', classes.scoringTitle)}>
            Podsumowanie
          </div>
          <DataRow
            containerClass="bg-light-grey"
            label="Łączna punktacja"
            value={
              <ProgressValue value={total_points} max={total_points_max} />
            }
          />
          <DataRow
            containerClass="bg-light-grey"
            label="Ocena scoringowa"
            value={
              <ProgressValue value={scoring} max={100} isPercentage={true} />
            }
          />
          <DataRow
            label="Punktacja analizy dochodów"
            value={
              <ProgressValue value={income_points} max={income_points_max} />
            }
          />
          <DataRow
            label="Punktacja z raportów BIG"
            value={<ProgressValue value={big_points} max={big_points_max} />}
          />
          <DataRow
            label="Punktacja z ilości zobowiązań"
            value={
              <ProgressValue
                value={liabilities_points}
                max={liabilities_points_max}
              />
            }
          />
          <DataRow
            label="Punktacja za brak rozdzielności majątkowej"
            value={
              <ProgressValue
                value={property_separation_points}
                max={property_separation_points_max}
              />
            }
          />
          <DataRow
            label="Punktacja za stałego klienta"
            value={
              <ProgressValue value={loyalty_points} max={loyalty_points_max} />
            }
          />
        </CCol>
        <CCol sm="4" xl="4">
          <div className={cs('mt-5 mb-3', classes.scoringTitle)}>
            Analiza dochodów (dane w tys. zł)
          </div>
          <IncomeAnalysis
            customerType={customerType}
            customerId={customerId}
            income_analysis={income_analysis}
            client_status={client_status}
            refetch={refetch}
          />
        </CCol>
        {/* <CCol sm="4" xl="4">
          <div className={cs('mt-5 mb-3', classes.scoringTitle)}>
            Analiza dochodów (dane w tys. zł)
          </div>
          <DataRow
            label="Dochód gosp.dom netto / mc"
            value={net_month_income}
          />
          <DataRow
            label="Liczba osób w gosp. dom."
            value={number_of_dependents}
          />
          <DataRow
            label="Koszty utrzymania / mc"
            value={net_month_living_cost}
          />
          <DataRow label="Ilość zobowiązań" value={liabilities_count} />
          <DataRow label="Łączne zadłużenie" value={total_debt} />
          <DataRow
            label="Obsługa zadłużenia / mc"
            value={net_month_liabilities_cost}
          />
          <DataRow label="Łączne wydatki / mc" value={net_total_expenses} />
          <DataRow
            containerClass="bg-light-grey"
            label="Nadwyżka / niedobór / mc"
            value={balance}
          />
          <DataRow
            label="Stan cywilny / rozdzielność majątkowa"
            value={
              !marital_status_text
                ? '-'
                : marital_status_text +
                  ' / ' +
                  (property_separation ? 'TAK' : 'NIE')
            }
          />
        </CCol> */}
        <CCol sm="4" xl="4">
          <div className={cs('mt-5 mb-3', classes.scoringTitle)}>
            Raporty BIG
          </div>
          <DataRow
            label="Weryfikacja PESEL z imieniem i nazwiskiem"
            value={
              pesel_ok === null ? (
                '-'
              ) : pesel_ok === true ? (
                <CBadge color={'success'} className="white-space-normal">
                  Weryfikacja poprawna
                </CBadge>
              ) : (
                <CBadge color={'danger'} className="white-space-normal">
                  Weryfikacja błędna
                </CBadge>
              )
            }
          />
          <DataRow
            label="Weryfikacja zastrzeżenia PESEL"
            value={
              pesel_restricted === false ? (
                <CBadge color={'success'} className="white-space-normal">
                  Niezastrzeżony
                </CBadge>
              ) : pesel_restricted === true ? (
                <CBadge color={'danger'} className="white-space-normal">
                  Zastrzeżony
                </CBadge>
              ) : (
                '-'
              )
            }
          />
          <DataRow
            label="Ilość informacji negatywnych BIK"
            value={bik_negative_count}
          />
          <DataRow
            label="Ilość informacji negatywnych BIG"
            value={big_negative_count}
          />
          <DataRow
            label="Ilość informacji pozytywnych w BIG"
            value={big_positive_count}
          />
          <DataRow
            label="Ilość zapytań w bazie BIG"
            value={big_queries_count}
          />
          <DataRow
            label="Data rejestracji działalności"
            value={entrepreneur_start_date}
          />
          <div className="mt-2">
            <div className="d-flex flex-fill my-2">
              <Button
                className="flex-fill"
                onClick={() => {
                  // const newWindow = window.open();
                  // newWindow.document.write(bik_report_link);
                  downloadFile(bik_report_link, 'raport_big.xml');
                }}
              >
                Wyświetl raport BIG
              </Button>
            </div>
            <div className="d-flex flex-fill my-2">
              <a
                href={krs_report_link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex-fill"
              >
                <Button className="w-100" disabled={!krs_report_link}>
                  Wyświetl KRS
                </Button>
              </a>
            </div>
            <div className="d-flex flex-fill my-2">
              <a
                href={statements_link}
                onClick={downloadStatement}
                target="_blank"
                rel="noopener noreferrer"
                className="flex-fill"
              >
                <Button className="w-100" disabled={!statements_link}>
                  Wyświetl zaświadczenie o zarobkach
                </Button>
              </a>
            </div>
          </div>
        </CCol>
      </CRow>
    </DataCard>
  );
};

export default ScoringBox;
